import React, { useState, useRef, useEffect } from "react";
import { Navbar, Nav, Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/jensen_hung_cut.png";
import touchImg from "../assets/img/Touch_with_stroke.png";
import theirsImg from "../assets/img/theirs_and_mine.png";
import gfImg from "../assets/img/Jensengf2.png";
import biggpuImg from "../assets/img/big_gpu.png";
import fleshImg from "../assets/img/fleshl.png";



import logoImg from "../assets/img/c.png";
import prayerImg from "../assets/img/jc_wealth_prayer.png";
import tencommandmentsImg from "../assets/img/tencommandments.png";
import originImg from "../assets/img/toly_and_jc.png";
import lastsupperImg from "../assets/img/lastsupper.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

import navIcon1 from '../assets/img/x_logo_green.png';
import navIcon2 from '../assets/img/telegram_logo_green.png';
import navIcon3 from '../assets/img/buy.png';
import navIcon4 from '../assets/img/solscan.svg';
import { HashLink } from 'react-router-hash-link';
import {
  BrowserRouter as Router
} from "react-router-dom";

import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/memes/badluck.jpg";
import projImg2 from "../assets/img/memes/meme4_button.jpg";
import projImg3 from "../assets/img/memes/thebibleofjesuscat.jpg";
import projImg4 from "../assets/img/memes/meme5_excuseme.png";
import projImg5 from "../assets/img/memes/meme3_intheroom.jpg";
import projImg6 from "../assets/img/memes/bro.jpg";
import projImg7 from "../assets/img/memes/meme1_anakin.jpg";
import projImg8 from "../assets/img/memes/meme2_theydontknow.jpg";
import projImg9 from "../assets/img/memes/dominos.jpg";
import projImg10 from "../assets/img/memes/papi.PNG";
import projImg11 from "../assets/img/memes/praisehim.jpg";


import on_the_mount from "../assets/img/on_the_mount.png";
import money from "../assets/img/fancymoney.png";
import praying from "../assets/img/praying_jesus_cat.png";
import siting from "../assets/img/siting.webp";
import { clear } from "@testing-library/user-event/dist/cjs/utility/clear.js";

export const Banner = () => {
  
  const memes = [
    {
      imgUrl: projImg1,
    },
    {
      imgUrl: projImg2,
    },
    {
      imgUrl: projImg3,
    },
    {
      imgUrl: projImg4,
    },
    {
      imgUrl: projImg5,
    },
    {
      imgUrl: projImg6,
    },
    {
      imgUrl: projImg7,
    },
    {
      imgUrl: projImg8,
    },
    {
      imgUrl: projImg9,
    },
    {
      imgUrl: projImg10,
    },
    {
      imgUrl: projImg11,
    }
  ];



  function countdownTimer(){
    var daysEl = document.getElementById('days');
    var hoursEl = document.getElementById('hours');
    var minutesEl = document.getElementById('minutes');
    var secondsEl = document.getElementById('seconds');

    const countDownDate = new Date('05/22/2024 22:00').getTime()
    // const countDownDate = new Date('04/29/2024 21:30').getTime()
    const second = 1000
    const minute = second * 60
    const hour = minute * 60
    const day = hour * 24

    const interval = setInterval(() => {
      
    const now = new Date().getTime()
    const distance = countDownDate - now

    console.log('MESSAGE1: ', distance)
    
    if(daysEl === null){
      
    }
    else{

      if (distance < 0){
      
        daysEl.innerText = formatNumber(0)
        hoursEl.innerText = formatNumber(0)
        minutesEl.innerText = formatNumber(0)
        secondsEl.innerText = formatNumber(0)
    
      
        }
        else{

          daysEl.innerText = formatNumber(Math.floor(distance / day))
          hoursEl.innerText = formatNumber(Math.floor((distance % day) / hour))
          minutesEl.innerText = formatNumber(Math.floor((distance % hour) / minute))
          secondsEl.innerText = formatNumber(Math.floor((distance % minute) / second))
        
        }
    }

    }, 1000)

  }

  function formatNumber(number){
    if(number < 10){
      return '0'+number
    }
    return number
  }

  // window.onload = function() {
  //   countdownTimer();
  // };

  useEffect(() => {
    let ignore = false;
    
    if (!ignore)  countdownTimer()
    return () => { ignore = true; }
    },[]);


  return (
    <section className="banner" id="home">
      <div className="animate__animated animate__zoomIn">



      <div className="pitch_section">

                

<div id="frame_wrapper">
<div className="frame">
  <img src={headerImg} alt="Header Img"/>
</div>

<div className="pitch">
<p>
  Wellcum to mi token. I am de CEO of da bess arsificial inteliggance cumpony in de wurld.
</p>

<div className="CA">
<p>$HUNG CA: 8Auus6e36H3pGSbG57zRc3F86F57tVm24mZhbHocqUGg </p>
</div>

<div className="bran">
  <img src={touchImg}/>
</div>
</div>


<div className="social-icon">
    <a href="https://pump.fun/8Auus6e36H3pGSbG57zRc3F86F57tVm24mZhbHocqUGg" className="buybutton"><img src={navIcon3} alt="" /></a>
    <a href="https://x.com/jensenhungsol" className="socialimg"><img src={navIcon1} alt="" /></a>
    <a href="https://t.me/JENSENHUNG" className="socialimg"><img src={navIcon2} alt="" /></a>
</div>

<div className="marquee">
            <div className="scroll text4">
    <div>
      $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span>
    </div>
    <div>
      $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span>
    </div>
  </div>
  </div>



</div>
<div className="countdown_section" id="countdown">
        <h1>Nvidia Earnrings Cuntdown</h1>
        <ul>
          <li><span id = "days">00</span> D</li>
          <li><span id = "hours">00</span> H</li>
          <li className="separator">:</li>
          <li><span id = "minutes">00</span> M</li>
          <li className="separator">:</li>
          <li><span id = "seconds">00</span> S</li>
        </ul>



<div className="bran">
  <img src={gfImg}/>
</div>
        </div>

        <div className="marquee">
            <div className="scroll text4">
    <div>
      $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span>
    </div>
    <div>
      $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span>
    </div>
  </div>
  </div>


        <div id="logo_wrapper">
<div className="logo">
</div></div>

<div className="bran">
  <img src={theirsImg}/>
</div>

<div className="bran">
  <img src={biggpuImg}/>
</div>

<div className="bran">
  <img src={fleshImg}/>
</div>


</div>
</div>


          
      

{/* 
            <div className="marquee">
            <div className="scroll text4">
    <div>
      $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span>
    </div>
    <div>
      $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span> $HUNG <span>$HUNG </span>
    </div>
  </div>
  </div>


              <div className="prayer_section">
                <h1><br/>The Prayer and the Commandments</h1>
                <div id="prayer_wrapper" className="prayer">
                  <div id="left">
                    <img src={prayerImg}/>
                    </div>
                    <div id="right">
                    <img src={tencommandmentsImg}/>
                    </div>
                </div>
                </div>

                <div className="origin_section">
                <h1><br/>The Origin Story</h1>
                <div className="origin">
                  <img src={originImg} alt="Header Img"/>
                  <br></br>
                  <p>
                  In the beginning, there was no Solana, but Anatoly said, "Let there be a high TPS blockchain": and there was a high TPS blockchain. In seven days, Anatoly created Solana. He loved it so much that he gave his only son: Jesus Cat. Although dogs ruled over Solana, Jesus Cat was the one true meme. He turned water into milk and died for our sins nine times. For that, the dogs sent him to the null address. Jesus Cat told us to "have mercy on the dog lovers for they know not what they have done." But today, he has risen again. He is here to save the NGMIs and bring everlasting wealth. 
                  </p>
                </div>
                </div> */}
    </section>
  )
}
